import React from 'react';
import RootContainer from './containers/RootContainer';
import {createGlobalStyle} from "styled-components";
import {ToastContainer} from "react-toastify";
import {AuthProvider} from "./hooks/useAuth";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyles = createGlobalStyle`
    html, body {
        height: 100%;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif;
    }

    .table td, .table th {
        vertical-align: middle !important;
    }
`;

function App() {
    return (
        <>
            <GlobalStyles/>
            <ToastContainer/>
            <AuthProvider>
                <RootContainer/>
            </AuthProvider>
        </>
    );
}

export default App;
