import React, {lazy, Suspense} from "react";
import {BrowserRouter, Navigate, useRoutes} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import Loading from "./components/Loading";

const SignInComponent = lazy(() => import('./pages/Auth/SignIn'));
const HomeComponent = lazy(() => import('./pages/Home'));

const RequestResetPasswordComponent = lazy(() => import('./pages/Auth/RequestResetPassword'));
const ResetPasswordComponent = lazy(() => import('./pages/Auth/ResetPassword'));
const SubscribersComponent = lazy(() => import('./pages/Subscribers'));
const SubscriptionsComponent = lazy(() => import('./pages/Subscriptions'));
const TransactionsComponent = lazy(() => import('./pages/Transactions'));
const CategoriesComponent = lazy(() => import('./pages/Categories/Categories'));
const ProjectsComponent = lazy(() => import('./pages/Projects/Projects'));
const ProjectComponent = lazy(() => import('./pages/Projects/Project/ProjectDetails'));
const PagesComponent = lazy(() => import('./pages/Pages/Pages'));
const ErrorComponent = lazy(() => import('./pages/Error'));
const ReceiptConfigComponent = lazy(() => import('./pages/Configs/ReceiptConfig'));
const MainLayout = lazy(() => import('./pages/Layout/MainLayout'));

const AppRoutes = () => {
    const { user } = useAuth();

    const routes = [
        {
            path: "/",
            element: user ? <MainLayout /> : <Navigate to="/sign-in" />,
            children: [
                { path: "", element: <HomeComponent /> },
                { path: "transactions", element: <TransactionsComponent /> },
                { path: "subscribers", element: <SubscribersComponent /> },
                { path: "subscriptions", element: <SubscriptionsComponent /> },
                { path: "settings", element: <HomeComponent /> },
                { path: "projects", element: <ProjectsComponent /> },
                { path: "categories", element: <CategoriesComponent /> },
                { path: "projects/:project_id", element: <ProjectComponent /> },
                { path: "pages", element: <PagesComponent /> },
                { path: "config/receipt", element: <ReceiptConfigComponent /> },
                { path: "config/payment", element: <ReceiptConfigComponent /> },
            ],
        },
        {
            path: "/sign-in",
            element: !user ? <SignInComponent /> : <Navigate to="/" />,
        },
        {
            path: "/reset-password/:recoveryToken",
            element: !user ? <ResetPasswordComponent /> : <Navigate to="/" />,
        },
        {
            path: "/request-reset-password",
            element: !user ? <RequestResetPasswordComponent /> : <Navigate to="/" />,
        },
        {
            path: "*",
            element: <ErrorComponent code={404} />,
        },
    ];

    return useRoutes(routes);
};


const RootContainer: React.FC = () => {
    const { loading } = useAuth();
    return (
        <BrowserRouter>
            {loading ? (
                <Loading />
            ) : (
                <Suspense fallback={<Loading />}>
                    <AppRoutes />
                </Suspense>
            )}
        </BrowserRouter>
    );
};

export default RootContainer;
